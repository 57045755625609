<template>
	<div class="graphicdetails">
		<!-- 处理其他文章类型
            article_types 文章类型 0=外链， 1=图文， 2=音频， 3=视频
            article_url  文章类型参数
        -->
		<div class="graphicdetails-wrap">
			<div class="graphicdetails-wrap-top">
				<p>{{ school_detail.title }}</p>
				<p>{{ school_detail.create_time }}</p>
			</div>
			<div class="graphicdetails-wrap-bottom">
				<p v-html="school_detail.long_contents"></p>
			</div>
			<ul class="graphicdetails-wrap-footer">
				<li @click="toLike">
					<svg t="1598596075246" class="icon" viewBox="0 0 1026 1024" version="1.1"
						xmlns="http://www.w3.org/2000/svg" p-id="4617" width="16" height="16">
						<path
							d="M797.952 1002.112H109.312A109.44 109.44 0 0 1 0 892.8V502.208c0-62.208 51.2-116.8 109.312-116.8h109.888c138.816 0 243.456-106.496 243.456-247.744v-28.8C462.656 50.88 515.2 0 575.104 0h2.944c45.952 0 88.256 30.464 102.848 74.048l7.168 20.288a356.928 356.928 0 0 1 8.384 200.64l-3.84 15.872c-1.28 10.56-6.336 29.952-15.424 48.832h241.728c33.856 0 65.28 15.936 86.08 43.584 21.12 28.032 28.096 64.576 18.56 97.92l-120.256 419.264a109.696 109.696 0 0 1-105.344 81.664zM109.312 436.8c-30.208 0-57.984 31.104-57.984 65.408v390.592c0 31.936 26.048 57.984 57.984 57.984h688.64a59.136 59.136 0 0 0 56.128-44.096l120.128-419.776a59.072 59.072 0 0 0-10.176-52.736 56.064 56.064 0 0 0-44.992-23.04H616.704V359.68c7.296-4.096 23.744-40.704 24.832-53.504l0.704-5.312 4.288-18.048a306.88 306.88 0 0 0-6.72-171.648l-7.424-20.288a59.008 59.008 0 0 0-54.336-39.552h-2.944c-31.488 0-61.12 28.032-61.12 57.536v28.8c0 170.56-126.784 299.136-294.784 299.136H109.312z"
							fill="#4D4D4D" p-id="4618" />
						<path
							d="M231.232 976.448a25.6 25.6 0 0 1-25.664-25.6V436.8a25.6 25.6 0 1 1 51.328 0v513.984a25.6 25.6 0 0 1-25.664 25.664z"
							fill="#4D4D4D" p-id="4619" />
					</svg>
					<p>{{ school_detail.total_like }}</p>
				</li>
				<li>
					<svg t="1598581790338" class="icon" viewBox="0 0 1024 1024" version="1.1"
						xmlns="http://www.w3.org/2000/svg" p-id="3798" width="16" height="16">
						<path
							d="M512 643.134694c-72.097959 0-131.134694-58.514286-131.134694-131.134694S439.902041 380.865306 512 380.865306s131.134694 58.514286 131.134694 131.134694-59.036735 131.134694-131.134694 131.134694z m0-220.47347c-49.110204 0-89.338776 40.228571-89.338776 89.338776s40.228571 89.338776 89.338776 89.338776 89.338776-40.228571 89.338776-89.338776-40.228571-89.338776-89.338776-89.338776z"
							p-id="3799" />
						<path
							d="M512 780.538776c-173.97551 0-321.828571-131.134694-394.44898-208.979592-30.82449-33.436735-30.82449-85.159184 0-118.595919 72.620408-77.844898 220.473469-208.979592 394.44898-208.979592s321.828571 131.134694 394.44898 208.979592c30.82449 33.436735 30.82449 85.159184 0 118.595919-72.620408 77.844898-220.473469 208.979592-394.44898 208.979592z m0-495.281633c-158.302041 0-295.706122 122.77551-364.146939 195.918367-16.195918 17.240816-16.195918 44.408163 0 61.64898 67.918367 73.142857 205.844898 195.918367 364.146939 195.918367s295.706122-122.77551 364.146939-195.918367c16.195918-17.240816 16.195918-44.408163 0-61.64898-68.440816-73.142857-205.844898-195.918367-364.146939-195.918367z"
							p-id="3800" />
					</svg>
					<p>{{ school_detail.total_read }}</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		SchoolDetail,
		SchoolArticleLike,
		SchoolArticleCancelLike
	} from "../../http/api";
	export default {
		data() {
			return {
				article_id: 0,
				school_detail: {},
				userToken: {}
			};
		},
		created() {},
		mounted() {
			if (this.$route.query.id) {
				this.article_id = this.$route.query.id;
				this.userToken = this.$LStorage.getItem("userToken")
				this.SchoolDetail();
			}
		},

		methods: {
			async SchoolDetail() {
				const res = await SchoolDetail({
					data: {
						article_id: this.article_id,
						user_token: this.userToken.user_token
					}
				});
				this.school_detail = res.data;
			},

			//  处理 用户点赞
			toLike() {
				if (this.school_detail.is_like == 0) {
					//  执行点赞
					this.setLike();
				} else {
					//  执行取消点赞
					this.unLike();
				}
			},

			// 点赞
			async setLike() {
				const res = await SchoolArticleLike({
					data: {
						article_id: this.article_id,
						user_token: this.userToken.user_token
					}
				});
				console.log(res);
				if (res.code == "200") {
					this.$toast("点赞成功");
					this.school_detail.total_like++;
					this.school_detail.is_like = 1;
				} else {
					this.$toast(res.msgs);
				}
			},

			async unLike() {
				const res = await SchoolArticleCancelLike({
					data: {
						article_id: this.article_id,
						user_token: this.userToken.user_token
					}
				});
				if (res.code == "200") {
					this.$toast("取消点赞成功");
					this.school_detail.total_like--;
					this.school_detail.is_like = 0;
				} else {
					this.$toast(res.msgs);
				}
			}
		}
	};
</script>

<style lang="scss">
	.graphicdetails {
		width: 100%;
		height: 100%;

		.my-top {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 0.3rem;
			box-sizing: border-box;
			font-size: 0.38rem;
			background: #f4f4f4;
		}

		.graphicdetails-wrap {
			margin: 0 auto;
			padding: 0.37rem 0.3rem 0.3rem 0.3rem;
			box-sizing: border-box;

			.graphicdetails-wrap-top {
				width: 100%;
				margin-bottom: 0.57rem;

				p:nth-child(1) {
					font-size: 0.35rem;
					margin-bottom: 0.27rem;
				}

				p:nth-child(2) {
					font-size: 0.28rem;
					color: #9b9b9b;
				}
			}

			.graphicdetails-wrap-bottom {
				width: 100%;
				font-size: 0.28rem;

				img {
					width: 100% !important;
				}
			}

			.graphicdetails-wrap-footer {
				display: flex;
				align-items: center;
				justify-content: space-around;
				margin-top: 0rem;
				box-sizing: border-box;

				li {
					width: 1.97rem;
					height: 0.66rem;
					border: 1px solid #cccccc;
					line-height: 0.66rem;
					text-align: center;
					font-size: 0.21rem;
					color: #333333;
					display: flex;
					align-items: center;
					text-align: center;
					justify-content: center;
					border-radius: 0.33rem;
					box-sizing: border-box;

					svg {
						margin-right: 0.24rem;
					}
				}
			}
		}
	}
</style>
